import * as React from "react"
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const StyledH1 = styled.h1`
  text-shadow: rgba(0, 0, 0, 0.6) 0px 3px 12px;
`

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div style={{ display: "grid" }}>
    {/* You can use a GatsbyImage component if the image is dynamic */}
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          height: 400,
        }}
        width={2200}
        alt=""
        src="../images/header/header-bg.jpg"
        placeholder='tracedSVG'
        formats={["auto", "webp", "avif"]}
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
      >
        {/* Any content here will be centered in the component */}
        <div className='w-full max-w-screen-lg flex flex-col xl:flex-row items-center mx-auto'>
          <div className='w-11/12 flex flex-col items-center mx-auto'>
            <StyledH1 className='text-4xl md:text-5xl font-extrabold text-white uppercase text-center mt-6'>
              <span className='leading-tight'>Page Not Found</span>
            </StyledH1>

            <Link to="/">
              <button className="bg-red-600 hover:bg-red-700 text-white font-display px-8 py-4 text-2xl font-semibold tracking-wide rounded mt-8 shadow-lg">
                Go to Home Page
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
